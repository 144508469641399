import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Toaster, toast } from "sonner";
import { Provider } from "react-redux";
import store from "./state/store";
import App from "./app";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Toaster
      position="top-center"
      duration={3000}
      toastOptions={{
        style: {
          background: "#FFF",
          color: "#121540;",
        },
      }}
    />
    <Provider store={store} children={<App />} />
  </React.StrictMode>
);
