import React from "react";
import HeaderLand from "../conponents/headerLand";
import EntrieScreenLand from "../conponents/screens/entrieScreenLand";
import ErrorScreen from "../conponents/screens/error";

const ErrorPage = () => {
  return (
    <div className="app mainPage columnCenter landing" style={{
      maxHeight: "100vh"
    }}>
      <ErrorScreen />
    </div>
  );
};

export default ErrorPage;