import React from "react";
import { SvgProps } from "../types";

export const RoundArrowIcon = (props: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 113 46"
      className={props.classNames?.join(" ") || ""}
    >
      <path d="M112.5 0.500122C108 35.5001 47.8 67.0521 1 24.2521M1 24.2521L5.5 40.2521M1 24.2521H17" fill="none" />
    </svg>
  );
};
