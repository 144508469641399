import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, RootState } from "./state/reducer";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./scss/app.scss";
import SignupPage from "./pages/signup";
import HomePage from "./pages/home";
import SigninPage from "./pages/signin";
import UserProfilePage from "./pages/profile";
import RoulettePage from "./pages/roulette";
import { backend } from "./services/backend";
import LandingPage from "./pages/landing";
import LoadingPage from "./pages/loading";
import PrivacyPolicyPage from "./pages/info/personal";
import RulesPage from "./pages/info/rules";
import ErrorPage from "./pages/error";
import CardReadPage from "./pages/card/read";
import CardEditPage from "./pages/card/edit";
import { cardThemes } from "./content";

const App = () => {
  const State = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [loading, Loaded] = useState(true);

  const handleRequestMessages = () => {
    backend.getCardNotifications().then((list) => {
      dispatch(actions.messages(list))
    }).catch((e) => {
      console.log(e)
    })
    backend.getCardFromNotifications().then((list) => {
      dispatch(actions.messagesFrom(list))
    }).catch((e) => {
      console.log(e)
    })
  }

  useMemo(() => {
    backend.getUserId().then((id) => {
      if (!id) {
        dispatch(actions.currentUser(null));
        return;
      }
      backend
        .getUserData(String(id))
        .then((user) => {
          dispatch(actions.currentUser(user));
        })
        .catch((e) => {
          console.log(e);
          dispatch(actions.currentUser(null));
        });
      handleRequestMessages()
      setInterval(() => {
        handleRequestMessages()
      }, 10000)
    });
  }, []);

  // Заглушка
  /* return (
    <BrowserRouter>
      <Routes>
        <Route path="/smstest" element={<HomePage />} />
        <Route path="*" element={<LandingPage />} />
      </Routes>
    </BrowserRouter>
  ); */

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signin" element={<SigninPage />} />
        <Route path="/profile" element={<UserProfilePage />} />
        <Route path="/loading" element={<LoadingPage />} />
        <Route path="/navigator" element={<UserProfilePage />} />
        {/* <Route path="/navigator" element={<RoulettePage stage="" />} />
        <Route path="/navigator/letter" element={<RoulettePage stage="letter" />} />
        <Route path="/navigator/sent" element={<RoulettePage stage="sent" />}  />*/}
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/card/:cardId" element={<CardReadPage type="card" />} />
        <Route path="/gift/:giftId" element={<CardReadPage type="gift" />} />
        <Route path="/rules" element={<RulesPage />} />
        <Route path="/cardedit" element={<CardEditPage theme="" />} />
        <Route path="/cardedit/template1" element={<CardEditPage theme={cardThemes.template1} />} />
        <Route path="/cardedit/template2" element={<CardEditPage theme={cardThemes.template2} />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
