import React from "react";
import HeaderLand from "../conponents/headerLand";
import EntrieScreenLand from "../conponents/screens/entrieScreenLand";

const LandingPage = () => {
  return (
    <div className="app mainPage columnCenter landing" style={{
      maxHeight: "100vh"
    }}>
      <HeaderLand />
      <EntrieScreenLand />
    </div>
  );
};

export default LandingPage;
