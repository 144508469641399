import React, { useEffect, useMemo, useState } from "react";
import Header from "../../conponents/header";
import LoadingPage from "../loading";
import { useParams } from "react-router-dom";
import ErrorPage from "../error";
import { backend, staticUrl } from "../../services/backend";
import { CardData, User } from "../../types";
import { Card } from "../../conponents/card";
import ProfileMenu from "../../conponents/menu/profileMenu";

const CardReadPage = (props: { type: "gift" | "card" }) => {
  const [errored, Error] = useState(false);
  const [displayData, setDisplayData] = useState<CardData>();
  const [gifterInfo, setGifterInfo] = useState<User>();
  const [recevierInfo, setRecevierInfo] = useState<User>();
  const [gifterBtnOpen, setGifterBtnOpen] = useState(false);
  const [recevierBtnOpen, setRecevierBtnOpen] = useState(false);
  const { giftId, cardId } = useParams();

  const usingId = Number(giftId || cardId);

  useEffect(() => {
    if (isNaN(usingId)) {
      Error(true);
      return;
    }

    const fetchData = async () => {
      try {
        if (props.type === "gift") {
          const res = await backend.getCardByGift(usingId);
          if (!res) {
            Error(true);
            return;
          }
          setDisplayData(res);
        } else {
          const res = await backend.getCard(usingId);
          if (!res) {
            Error(true);
            return;
          }
          setDisplayData(res);
        }
      } catch (error) {
        Error(true);
      }
    };

    fetchData();
  }, [usingId, props.type]);

  useMemo(() => {
    if (giftId) {
      backend.getGiftSender(Number(giftId)).then((user) => {
        if (user) {
          setGifterInfo(user);
        }
      });
      backend.getGiftRecevier(Number(giftId)).then((user) => {
        if (user) {
          setRecevierInfo(user);
        }
      });
    }
  }, []);

  if (errored) {
    return <ErrorPage />;
  }

  if (!displayData) {
    return <LoadingPage />;
  }

  return (
    <div className="app profilePage columnCenter cardPage">
      <Header />
      <div style={{ height: 100 }} />
      <div className="section">
        <div className="roulette">
          <div className="inner">
            <div className="flex columnCenter rouletteHeading">
              <Card card={displayData} editable={false} />
              <div className="giftUsersInfo">
                {gifterInfo ? (
                  <>
                    <div
                      className="btn roulettMainBtn columnCenter mainSubmitBtn"
                      onClick={() => {
                        setGifterBtnOpen((state) => !state);
                      }}
                    >
                      {!gifterBtnOpen
                        ? "Показать Деда Мороза"
                        : "Скрыть Деда Мороза"}
                    </div>
                    {gifterBtnOpen ? (
                      <div className="gifterInfo">
                        <div className="flex imageBinContainer">
                          <div className="imageTo">
                            <img
                              src={
                                gifterInfo.photo_url
                                  ? `${staticUrl}${gifterInfo.photo_url}`
                                  : "/images/default.png"
                              }
                            />
                          </div>
                        </div>
                        <div className="descriptionCtnr">
                          <div className="descHead">Имя:</div>
                          <div className="descText">{`${
                            gifterInfo.last_name || ""
                          } ${gifterInfo.first_name || ""}`}</div>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}
                {recevierInfo ? (
                  <>
                    <div
                      className="btn roulettMainBtn columnCenter mainSubmitBtn"
                      onClick={() => {
                        setRecevierBtnOpen((state) => !state);
                      }}
                    >
                      {!recevierBtnOpen
                        ? "Показать Счастливчика"
                        : "Скрыть Счастливчика"}
                    </div>
                    {recevierBtnOpen ? (
                      <div className="">
                        <div className="gifterInfo">
                          <div className="flex imageBinContainer">
                            <div className="imageTo">
                              <img
                                src={
                                  recevierInfo.photo_url
                                    ? `${staticUrl}${recevierInfo.photo_url}`
                                    : "/images/default.png"
                                }
                              />
                            </div>
                          </div>
                          <div className="descriptionCtnr">
                            <div className="descHead">Имя:</div>
                            <div className="descText">{`${
                              recevierInfo.last_name || ""
                            } ${recevierInfo.first_name || ""}`}</div>
                          </div>
                        </div>
                        <div className="gifterInfo">
                          <div className="descriptionCtnr">
                            <div className="descText">Пожелания:</div>
                          </div>
                          <div className="descriptionCtnr">
                            <div className="descText">{recevierInfo.wish || ""}</div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}
                <div style={{ height: 100 }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardReadPage;
