import React, { useEffect, useMemo, useState } from "react";
import { z } from "zod";
import InputMask from "react-input-mask";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CloseIcon } from "../icons/close";
import ToggleSwitch from "./toggleSwitch";
import { Star4Icon } from "../icons/star4";
import { backend } from "../services/backend";
import { Navigate } from "react-router-dom";
import { RoundArrowIcon } from "../icons/roundArrow";
import ErrorMessage from "./screens/errorMessage";

const confirmTimeLimit = 900000;
const timePastStr = "00:00";
const wishLengthLimit = 200;

const registrationSchema = z
  .object({
    internalId: z.string().min(1, "id должен быть заполнен"),
    department: z.string().min(1, "Поле должно быть заполнено").default(""),
    phone: z.string().min(10, "Введите корректный номер телефона"),
    name: z.string().min(2, "Имя обязательно для заполнения"),
    last_name: z.string().min(2, "Фамилия обязательная для заполнения"),
    wishes: z.string(),
  })
  .refine(
    async (data) => {
      const { internalId, department, phone } = data;
      try {
        const res = await backend.validateIdByGroup(
          internalId,
          department,
          phone
        );
        return res; // Возвращаем результат проверки
      } catch (error) {
        // Пробрасываем кастомное сообщение об ошибке
        throw new z.ZodError([
          {
            code: "custom",
            message: error.error || error.message || "Неизвестная ошибка",
            path: ["internalId"],
          },
        ]);
      }
    },
    {
      message: "Табельный номер не соответствует подразделению",
      path: ["internalId"],
    }
  );

const confirmPasswordSchema = z
  .object({
    internalId: z.string(),
    password: z.string().min(6, "Пароль состоит минимум из 6 символов"),
  })
  .refine(
    async (data) => {
      const isValid = await backend.checkNewPassword(data);
      return !!isValid;
    },
    {
      message: "Неверный пароль",
      path: ["password"],
    }
  );

type RegistrationFormSchemaType = z.infer<typeof registrationSchema>;
type ConfirmPasswordSchemaType = z.infer<typeof confirmPasswordSchema>;

const MainPageForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors: stageOneErrors },
  } = useForm<RegistrationFormSchemaType>({
    resolver: zodResolver(registrationSchema),
    shouldFocusError: false
  });
  const {
    register: registerStage2,
    handleSubmit: handleSubmitStage2,
    formState: { errors: stageTwoErrors },
  } = useForm<ConfirmPasswordSchemaType>({
    resolver: zodResolver(confirmPasswordSchema),
  });
  const [groups, setGroups] = useState<string[]>([]);
  const [pending, Pending] = useState(false);
  const [savedInternalId, saveInternalId] = useState("");
  const [initials, saveInitials] = useState({
    name: "",
    surname: "",
    wish: "",
  });
  const [isPasswordRequested, setPasswordRequest] = useState(false); // false
  const [isAuthSuccess, setAuthSuccess] = useState(false);
  const [wrongInputClosed, setWIClosed] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [personalAgreed, setPersonalAgreed] = useState(false);
  const [responsible, setResponsible] = useState(false);
  const [confirmTimestampLimit, setConfirmTimestampLimit] = useState(0); // now + 900000
  const [confirmElapsedTimeString, updateConfirmTimeStr] = useState("14:59");

  useEffect(() => {
    const timeLimit = new Date().getTime() + confirmTimeLimit; // 15 minutes from now
    setConfirmTimestampLimit(timeLimit);

    const timeOut = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeRemaining = confirmTimestampLimit - currentTime; // Time remaining in milliseconds

      if (timeRemaining <= 0) {
        updateConfirmTimeStr(timePastStr); // Time is up
        clearInterval(timeOut); // Stop the timer
        return;
      }

      const minutes = Math.floor(timeRemaining / 60000); // Convert ms to minutes
      const seconds = Math.floor((timeRemaining % 60000) / 1000); // Convert remainder to seconds

      // Format time as mm:ss
      const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;

      updateConfirmTimeStr(formattedTime);
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timeOut);
  }, [confirmTimestampLimit]);

  useEffect(() => {
    backend.getAvailableGroups().then((groups) => setGroups(groups));
  }, []);

  useEffect(() => {
    setWIClosed(false);
  }, [stageOneErrors]);

  const getErrorMessages = () => {
    const messages: string[] = [];
    for (let key in stageOneErrors) {
      messages.push(stageOneErrors[key].message.replace("Required", "").replace("error", ""));
    }
    for (let key in stageTwoErrors) {
      messages.push(stageTwoErrors[key].message.replace("Required", "").replace("error", ""));
    }
    return messages;
  };

  const err = getErrorMessages();

  const backToBegin = () => {
    setPasswordRequest(false);
  };

  const onSubmit = async (data: RegistrationFormSchemaType) => {
    try {
      Pending(true);
      const result = await registrationSchema.parseAsync(data); // Асинхронная валидация
      await backend
        .requestPassword({
          internalId: data.internalId,
          groupName: data.department,
          phone: data.phone,
        })
        .then(() => {
          saveInternalId(data.internalId);
          setPasswordRequest(true);
          const timeLimit = new Date().getTime() + 900000;
          setConfirmTimestampLimit(timeLimit);
        });
      saveInitials({
        name: data.name,
        surname: data.last_name,
        wish: data.wishes,
      });
      // Продолжайте обработку данных
    } catch (e) {
      console.error("Ошибка валидации:", e.errors); // Обрабатывайте ошибки
    } finally {
      Pending(false);
    }
  };

  const onPasswordSubmit = async (data: ConfirmPasswordSchemaType) => {
    try {
      Pending(true);
      const result = await confirmPasswordSchema.parseAsync(data); // Асинхронная валидация
      backend
        .createUser({
          password: data.password,
          internalId: data.internalId,
        })
        .then((result) => {
          backend
            .updateUserProfile(initials)
            .then((res) => {
              console.log(res);
            })
            .catch((e) => {
              console.log(e);
            });
          saveInternalId(data.internalId);
          setPasswordRequest(false);
          setAuthSuccess(true);
        });
      // Продолжайте обработку данных
    } catch (e) {
      console.error("Ошибка валидации:", e.errors); // Обрабатывайте ошибки
    } finally {
      Pending(false);
    }
  };

  const wishesValue = watch("wishes", "");

  const isRegistrationDisabled =
    pending || !agreed || !responsible || !personalAgreed;

  if (isAuthSuccess) {
    return (
      <div className="mainPageForm">
        <a id="signup" href="#signup">
          {"  "}
        </a>
        <form className="columnCenter confirmSuccessStage">
          <div className="formLetterInner">
            <div className="formHeading inner flex between inputRow columnCenter">
              <h2>Поздравляем!</h2>
            </div>
            <div className="">
              <div className="columnCenter">
                <div className="width50 columnCenter">
                  <div>Вы успешно авторизованы!</div>
                  <div
                    className="btn flex mainSubmitBtn"
                    style={{ marginTop: 20 }}
                    onClick={() => (document.location.href = "/profile")}
                  >
                    <div className="submitText">Войти</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="checkboxContainer"
              style={{ visibility: "hidden", height: 0, marginTop: -90 }}
            >
              <div className="flex checkboxRow">
                <ToggleSwitch
                  checked={true}
                  onChange={(checked) => setResponsible(checked)}
                />
                <span>
                  Я согласен быть ответственным Дедом Морозом и отправить
                  подарок своему коллеге, выбранному случайным образом
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  if (isPasswordRequested) {
    return (
      <div className="mainPageForm">
        <a id="signup" href="#signup">
          {"  "}
        </a>
        <form
          onSubmit={handleSubmitStage2(onPasswordSubmit)}
          className="columnCenter confirmPasswordStage"
        >
          <div className="formLetterInner">
            <div className="formHeading inner flex between inputRow">
              <h2>Подтвердите пароль</h2>
              <div className="flex">
                <div className="requireSymbol" />
                <div className="sampleText">обязательны для ввода</div>
              </div>
            </div>
            <div className="inner">
              <div className="columnCenter">
                <label>{`Пароль (отправлен в СМС на указанный номер):`}</label>
                <input
                  type="hidden"
                  {...registerStage2("internalId")}
                  value={savedInternalId}
                />
                <div className="inputFullWidth width50 columnCenter">
                  <input {...registerStage2("password")} />
                  <div className="requireSymbol" />
                  {stageTwoErrors.password ? (
                    <p className="errorInputText">
                      {stageTwoErrors.password?.message}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="columnCenter timerBody">
                {confirmElapsedTimeString !== timePastStr ? (
                  <>
                    <p>Время на подтверждение пароля:</p>
                    <div className="timerItem timerValue">
                      {confirmElapsedTimeString}
                    </div>
                  </>
                ) : (
                  <div
                    className="timerItem timerValue"
                    style={{ marginBottom: 100 }}
                  >
                    <div
                      className="svgBth textBtn"
                      onClick={() => setPasswordRequest(false)}
                    >
                      <p>Запросить пароль ещё раз</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="checkboxContainer"
              style={{ visibility: "hidden", height: 0, marginTop: -90 }}
            >
              <div className="flex checkboxRow">
                <ToggleSwitch
                  checked={true}
                  onChange={(checked) => setResponsible(checked)}
                />
                <span>
                  Я согласен быть ответственным Дедом Морозом и отправить
                  подарок своему коллеге, выбранному случайным образом
                </span>
              </div>
            </div>
            <div className="columnCenter">
              {confirmElapsedTimeString !== timePastStr && (
                <button
                  className="btn flex mainSubmitBtn"
                  type="submit"
                  disabled={pending}
                >
                  <Star4Icon width={27} height={27} />
                  <div className="submitText">Отправить</div>
                </button>
              )}
            </div>
          </div>
          {/* <div className="formLetterFooter">
            <div className="openQRCode">
              <img src="/images/code.png" width="146" height="146" />
            </div>
          </div> */}
        </form>
      </div>
    );
  }

  return (
    <div className="mainPageForm">
      <a id="signup" href="#signup">
        {" "}
      </a>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
        className="columnCenter"
      >
        <div className="formLetterInner">
          {err.length > 0 && !wrongInputClosed ? (
            <div className="flex wrongMessageContainer">
              <div className="wrongMessage">
                {err.map((item, index) => {
                  return (
                    <ErrorMessage key={`mainerrmsg${index}`} text={item} />
                  );
                })}
              </div>
              <div className="svgBtn mfClose" onClick={() => setWIClosed(true)}>
                <CloseIcon width={19} height={18} />
              </div>
            </div>
          ) : null}
          <div className="formHeading inner flex between inputRow">
            <h2>Регистрация в клуб анонимных Дедов Морозов</h2>
            <div className="flex">
              <div className="requireSymbol" />
              <div className="sampleText">обязательны для ввода</div>
            </div>
          </div>
          <div className="inner">
            <div>
              <label>{`Порядковый номер сотрудника (Табельный номер):`}</label>
              <div className="inputFullWidth">
                <input {...register("internalId")} />
                <div className="requireSymbol" />
              </div>
            </div>
            <div className="flex between inputRow">
              <div className="column50">
                <label>Фамилия</label>
                <div className="inputFullWidth">
                  <input {...register("last_name")} />
                  <div className="requireSymbol" />
                </div>
              </div>

              <div className="column50">
                <label>Имя</label>
                <div className="inputFullWidth">
                  <input {...register("name")} />
                  <div className="requireSymbol" />
                </div>
              </div>
            </div>
            <div className="flex between inputRow">
              <div className="column50">
                <label>Подразделение</label>
                <div className="inputFullWidth">
                  <select {...register("department")} defaultValue="">
                    <option
                      style={{
                        opacity: 0.7,
                      }}
                      value=""
                      disabled
                    >
                      Выберите подразделение
                    </option>
                    {groups.map((item, index) => {
                      return (
                        <option key={`mfgrp${index}`} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  <div className="requireSymbol" />
                </div>
              </div>
              <div className="column50">
                <label>Номер телефона</label>
                <div className="inputFullWidth">
                  <Controller
                    control={control}
                    name="phone"
                    defaultValue=""
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        mask="+7 (999) 999-99-99"
                        placeholder="+7 (___) ___-__-__"
                        onBlur={field.onBlur} // Передаем событие blur вручную
                        inputRef={field.ref} // Указываем реф для InputMask
                      >
                       {(inputProps: any) => <input {...inputProps} />}
                      </InputMask>
                    )}
                  />
                  <div className="requireSymbol" />
                </div>
              </div>
            </div>
            <div>
              <label>
                Помоги Деду Морозу выбрать тебе подарок: расскажи что любишь,
                чем увлекаешься, или подскажи — у кого из коллег можно
                разузнать. Пиши через запятую
              </label>
              <div className="inputFullWidth">
                <textarea
                  {...register("wishes")}
                  rows={5}
                  maxLength={wishLengthLimit}
                />
                <div className="wishLimitHint">
                  {`${wishesValue.length} / ${wishLengthLimit}`}
                </div>
              </div>
            </div>
            <div className="checkboxContainer">
              <div className="flex checkboxRow">
                <ToggleSwitch
                  checked={personalAgreed}
                  onChange={(checked) => setPersonalAgreed(checked)}
                />
                <span>
                  Я даю согласие на обработку персональных данных в соответствии
                  с <a href="/privacy-policy">политикой конфиденциальности</a>
                </span>
              </div>
              <div className="flex checkboxRow">
                <ToggleSwitch
                  checked={agreed}
                  onChange={(checked) => setAgreed(checked)}
                />
                <span>
                  Я соглашаюсь вступить в Клуб анонимных Дедов Морозов
                </span>
              </div>
              <div className="flex checkboxRow">
                <ToggleSwitch
                  checked={responsible}
                  onChange={(checked) => setResponsible(checked)}
                />
                <span>
                  Я согласен быть ответственным Дедом Морозом и отправить
                  подарок своему коллеге, выбранному случайным образом
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="formLetterFooter">
          <button
            className={`btn flex mainSubmitBtn${
              isRegistrationDisabled ? " disabled" : ""
            }`}
            type="submit"
            disabled={isRegistrationDisabled}
          >
            <Star4Icon width={27} height={27} />
            <div className="submitText">Отправить</div>
          </button>
          <div className="openQRCode">
            <img src="/images/code.png" width="146" height="146" />
          </div>
          <div className="qrCodeHint">
            <h4 className="qrCodeHintText">
              Скачай Новогодние стикеры для Телеграм
            </h4>
            <RoundArrowIcon width={113} height={46} />
          </div>
        </div>
        <div style={{ height: 50 }} />
      </form>
    </div>
  );
};

export default MainPageForm;
