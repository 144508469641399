import React from "react";
import { SvgProps } from "../types";

export const CloseIcon = (props: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 19 18"
      className={props.classNames?.join(" ") || ""}
    >
      <path d="M1.00024 0.499878L18.0002 17.4999M18.0002 0.499878L1.00024 17.4999" />
    </svg>
  );
};
