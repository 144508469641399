import { GiftIcon } from "../../icons/gift";
import React, { useEffect, useState } from "react";

const ErrorScreen = () => {
  return (
    <div className="section loadingSection">
      <div className="preloader columnCenter">
        <div className="columnCenter">
          <GiftIcon width={85} height={76} />
          <h1>{404}</h1>
          <p style={{ color: "#FFF" }}>
            Страница не найдена, <a href="/">вернуться на главную</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorScreen;
