import { createReducer, createAction, combineReducers } from '@reduxjs/toolkit';
import { RouletteStages, GiftMessage, User, CardData, CardNotifyInfo } from '../types';
import { defaultCardConfig } from '../content';

const selectedItem: string = "";
const defaultMessages: CardNotifyInfo[] = []

type stringAction = {
    type: string,
    payload: string
}

type numberAction = {
    type: string;
    payload: number;
}

type booleanAction = {
    type: string;
    payload: boolean;
}

export const actionNames = {
    selectItem: "SELECT_ITEM",
    modal: "OPEN_MODAL",
    giftStage: "GIFT_STAGE",
    giftRecevier: "GIFT_RECEVIER_DATA",
    currentUser: "CURRENT_USER",
    loadMessages: "LOAD_MESSAGES",
    loadFromMessages: "LOAD_FROM_MESSAGES",
    giftsLeft: "GIFTS_LEFT",
    editCard: "EDIT_CARD",
    hideNotify: "HIDE_NOTIFY"
}

export const actions = {
    SelectItem: createAction<string>(actionNames.selectItem),
    modal: createAction<string>(actionNames.modal),
    giftStage: createAction<RouletteStages>(actionNames.giftStage),
    giftRecevier: createAction<User | null>(actionNames.giftRecevier),
    currentUser: createAction<User | null>(actionNames.currentUser),
    messages: createAction<CardNotifyInfo[]>(actionNames.loadMessages),
    messagesFrom: createAction<CardNotifyInfo[]>(actionNames.loadFromMessages),
    giftsLeft: createAction<number>(actionNames.giftsLeft),
    editCard: createAction<CardData>(actionNames.editCard),
    hideNotify: createAction<boolean>(actionNames.hideNotify)
}

const itemSelector = (state = selectedItem, action: stringAction) => {
    const newState = (action.type === actionNames.selectItem) ? 
    action.payload : state
    return newState
}

const activeModal = (state = "none", action: stringAction) => {
    const newState = (action.type === actionNames.modal) ? 
    action.payload : state
    return newState
}

const setGiftStage = (state = "", action: { type: string, payload: RouletteStages}) => {
    const newState = (action.type === actionNames.giftStage) ? 
    action.payload : state
    return newState
}

const setGiftRecevier = (state = null, action: {type: string, payload: User | null}) => {
    const newState = (action.type === actionNames.giftRecevier) ? 
    action.payload : state
    return newState
}

const setCurrentUser = (state = null, action: {type: string, payload: User | null}) => {
    const newState = (action.type === actionNames.currentUser) ? 
    action.payload : state
    return newState
}

const setMessages = (state = defaultMessages, action: {type: string, payload: CardNotifyInfo[]}) => {
    const newState = (action.type === actionNames.loadMessages) ? 
    action.payload : state
    return newState
}

const setMessagesFrom = (state = defaultMessages, action: {type: string, payload: CardNotifyInfo[]}) => {
    const newState = (action.type === actionNames.loadFromMessages) ? 
    action.payload : state
    return newState
}

const setGiftsLeft = (state = 3, action: numberAction) => {
    const newState = (action.type === actionNames.giftsLeft) ? 
    action.payload : state
    return newState
}

const setEditCard = (state = defaultCardConfig, action: { type: string, payload: CardData}) => {
    const newState = (action.type === actionNames.editCard) ? 
    action.payload : state
    return newState
}

const setHideNotify = (state = false, action: booleanAction) => {
    const newState = (action.type === actionNames.hideNotify) ? 
    action.payload : state
    return newState
}

export const RootReducer = combineReducers ({
    selectedItem: itemSelector,
    modal: activeModal,
    giftStage: setGiftStage,
    giftRecevier: setGiftRecevier,
    giftsLeft: setGiftsLeft,
    currentUser: setCurrentUser,
    messages: setMessages,
    messagesFrom: setMessagesFrom,
    editCard: setEditCard,
    hideNotify: setHideNotify
})

export type RootState = ReturnType<typeof RootReducer>