import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileHeader from "../../conponents/profileHeader";
import Header from "../../conponents/header";
import TextPageScreen from "../../conponents/screens/textScreen";

const RulesPage = () => {
  return (
    <div className="app profilePage columnCenter">
      <Header />
      <div className="section screenSection">
        <ProfileHeader />
        <TextPageScreen>
          <h5>Правила Клуба анонимных Дедов Морозов</h5>
          <p>
            <strong>1. Период работы клуба</strong>
          </p>
          <ul>
            <li>Вступи в клуб с 9 до 20 декабря.</li>
            <li>Положи подарки в мешок Деда Мороза с 18 по 24 декабря.</li>
            <li>Получи подарки от Деда Мороза с 25 по 28 декабря.</li>
          </ul>

          <p>
            <strong>2. Зарегистрируйся в клубе</strong>
          </p>
          <ul>
            <li>
              Для участия необходимо зарегистрироваться, заполнив форму.
              Обязательные поля отмечены звёздочкой (*).
            </li>
            <li>
              Укажи свой табельный номер, подразделение, Ф. И. О., номер
              телефона и подсказки-пожелания к подарку. Подарки указывай через
              запятую.
            </li>
            <li>
              <strong>Внимание:</strong> если табельный номер не соответствует
              подразделению, система выдаст ошибку.
            </li>
            <li>
              После нажатия кнопки «Зарегистрироваться» тебе на телефон придёт
              код подтверждения, который нужно ввести для завершения
              регистрации.
            </li>
          </ul>

          <p>
            <strong>3. Изучи возможности личного кабинета</strong>
          </p>
          <ul>
            <li>После регистрации откроется твой личный кабинет.</li>
            <li>
              В кабинете будут отображаться твои данные (Ф. И. О.,
              подразделение, пожелания-подсказки для других тайных Дедов
              Морозов).
            </li>
            <li>Ты можешь загрузить фото или установить аватар.</li>
            <li>
              Можешь остаться анонимным или показать своё имя – как захочешь.
              Для этого есть специальная галочка.
            </li>
          </ul>

          <p>
            <strong>4. Выбери получателя подарка</strong>
          </p>
          <ul>
            <li>
              В личном кабинете нужно запустить колесо. Это навигатор Деда
              Мороза. Крутишь и случайным образом находишь коллегу, которому
              будешь дарить подарок: это твой счастливчик, а может, и не один.
            </li>
            <li>
              Можно покрутить один, два или три раза и подарить подарки одному,
              двум или даже трём коллегам.
            </li>
            <li>
              <strong>Внимание!</strong> Если выбрал человека – он твой
              счастливчик, не забудь о нём!
            </li>
          </ul>

          <p>
            <strong>5. Купи подарок</strong>
          </p>
          <ul>
            <li>
              Ты становишься тайным Дедом Морозом для своего счастливчика. Узнай
              в подсказках или у коллег, что ему нравится, а если их нет –
              прояви фантазию и купи подарок на своё усмотрение.
            </li>
          </ul>

          <p>
            <strong>6. Положи подарки в мешок Деда Мороза</strong>
          </p>
          <ul>
            <li>
              Упакуй подарки и используй специальный стикер, чтобы подписать их.
            </li>
            <li>Положи подарки в специальный мешок Деда Мороза.</li>
          </ul>
          <p style={{ textAlign: "center" }}>
          <p className="flex illList" style={{justifyContent: "center" }}>
            <img className="illustration" src="/images/content/c1.png" />
            <img className="illustration" src="/images/content/c2.png" />
          </p>
          </p>
          <p>
            <strong>7. Сохраняй анонимность</strong>
          </p>
          <ul>
            <li>
              Кто чей Дед Мороз – остаётся тайной до момента вручения подарков.
            </li>
          </ul>

          <p>
            <strong>8. Создай новогоднюю открытку</strong>
          </p>
          <ul>
            <li>
              Выбери шаблон. Основой открытки служит готовый бланк с праздничным
              дизайном.
            </li>
            <li>
              Добавь текст поздравления. В специальное поле введи своё
              пожелание. Ты можешь сделать поздравление личным и тёплым!
            </li>
            <li>
              Укрась открытку стикерами. С правой стороны доступен набор
              стикеров: подарки, Деды Морозы, ёлки и другие элементы. Просто
              перетащи стикеры на открытку, размещай их в любом месте, меняй
              размеры и поворачивай.
            </li>
            <li>
              Сохрани готовую открытку. Нажми кнопку «Сохранить», чтобы скачать
              готовую открытку на своё устройство.
            </li>
            <li>
              Поделись настроением. Отправь открытку друзьям или коллегам через
              мессенджеры, электронную почту или распечатай.
            </li>
          </ul>
          <p style={{textAlign: "center" }}>
            <img className="illustration" src="/images/content/c3.png" />
          </p>
          <p>
            <strong>9. Получи подарки</strong>
          </p>
          <ul>
            <li>Получи подарки в отделе HR или у своего руководителя.</li>
            <li>
              <strong>Сюрприз!</strong> Ещё тебя ждёт подарок компании!
            </li>
          </ul>
          <h5>Сделай Новый год незабываемым для себя и для своих друзей!</h5>
          <div style={{ height: 50 }} />
        </TextPageScreen>
      </div>
    </div>
  );
};

export default RulesPage;
