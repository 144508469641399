import { UrlItem } from "../types";

export const mainMenuTabs: UrlItem[] = [
    {
        name: "Главная",
        url: "/"
    },
    {
        name: "Правила",
        url: "/rules"
    },
    {
        name: "Личный кабинет",
        url: "/profile"
    },
    /* {
        name: "Открытка",
        url: "/cardedit/template1"
    } */
]

export const profileMenuTabs: UrlItem[] = [
    {
        url: "/profile",
        name: "Личный кабинет"
    },
    {
        url: "/navigator",
        name: "Навигатор Деда Мороза"
    },
    {
        url: "/wish",
        name: "Пожелание"
    },
    {
        url: "/gift",
        name: "Отправка подарка"
    }
];

export const footerMenuTabs: UrlItem[] = [
    {
        url: "/rules",
        name: "Правила платформы"
    },
    {
        url: "/privacy-policy",
        name: "Политика конфиденциальности"
    }
];