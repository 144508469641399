import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, RootState } from "../../state/reducer";
import { GiftMessage } from "../../types";
import { HideIcon } from "../../icons/hide";
import { CloseIcon } from "../../icons/close";
import { giftMessageExample } from "../../content/messages";
import { truncateString } from "../../content/utils";

const NotifyBlockDesktop = (props: { onClose: () => void }) => {
  const State = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const notifyList = State.messages;
  const notifyFromList = State.messagesFrom;
  const unreadCount = State.messages.filter((msg) => !msg.is_read).length;

  return (
    <div className="notifyBlock pc">
      <div className="arrowTop" />
      <div className="flex between notifyPcHeading">
        <div className="nHeadingText">Уведомления</div>
        {unreadCount > 0 ? (
          <div className="flex notifyCounterBlock">
            <div className="notifyAlert" />
            <div className="notifyAlertCounter">{unreadCount}</div>
          </div>
        ) : null}
      </div>
      <div className="notifyPcBody">
        {notifyList.length === 0 ? (
          <div className="emptyList">Пока нет новых уведомлений</div>
        ) : (
          notifyList.map((item, index) => {
            const displayDate = item.date
              ? (() => {
                  const dt = new Date(item.date);
                  return `${dt.getDate()}.${dt.getMonth() + 1}.${dt.getFullYear()}`;
                })()
              : "";
            return (
              <div
                key={`ntpc${index}`}
                className={`flex between notifyPcItem${
                  !item.is_read ? " unread" : ""
                }`}
              >
                <div className="messageText">
                  <a
                    href={
                      item.gift_id
                        ? `/gift/${item.gift_id}`
                        : `/card/${item.id}`
                    }
                  >
                    {item.gift_id
                      ? "Вам отправили подарок"
                      : "Вам отправили открытку"}
                  </a>
                </div>
                <div className="messageDate">{displayDate}</div>
              </div>
            );
          })
        )}
        {notifyFromList.map((item, index) => {
          const displayDate = item.date
            ? (() => {
                const dt = new Date(item.date);
                return `${dt.getDate()}.${dt.getMonth() + 1}.${dt.getFullYear()}`;
              })()
            : "";
          return (
            <div
              key={`ntpc${index}`}
              className="flex between notifyPcItem"
            >
              <div className="messageText">
                <a
                  href={
                    item.gift_id ? `/gift/${item.gift_id}` : `/card/${item.id}`
                  }
                >
                  {item.gift_id
                    ? "Вы отправили подарок"
                    : "Вы отправили открытку"}
                </a>
              </div>
              <div className="messageDate">{displayDate}</div>
            </div>
          );
        })}
      </div>
      <div
        className="flex between notifyPcHeading"
        style={{
          borderRadius: "0 0 20px 20px",
        }}
      >
        <div
          className="btn flex hideAll"
          onClick={() => {
            props.onClose();
            dispatch(actions.hideNotify(true));
          }}
        >
          <HideIcon width={16} height={16} />
          <span>Скрыть все</span>
        </div>
        <div className="btn flex close" onClick={props.onClose}>
          <CloseIcon width={16} height={16} />
          <span>Закрыть</span>
        </div>
      </div>
    </div>
  );
};

export default NotifyBlockDesktop;
