import React from "react";
import { SvgProps } from "../types";

export const FileIcon = (props: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 15 17"
      className={props.classNames?.join(" ") || ""}
    >
      <path d="M13.5425 15.7731V1.34766H5.692L1.18903 6.15613V10.9646V15.7731H13.5425Z" />
    </svg>
  );
};
