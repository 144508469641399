import React, { useEffect, useState } from "react";
import Header from "../conponents/header";
import { useSelector, useDispatch } from "react-redux";
import EntrieScreen from "../conponents/screens/entrieScreen";
import LoginScreen from "../conponents/screens/loginScreen";

const SigninPage = () => {
  return (
    <div className="app mainPage contentPage columnCenter">
      <Header />
      <LoginScreen />
    </div>
  );
};

export default SigninPage;
