import {
  CardData,
  CardNotifyInfo,
  CodeRequest,
  CodeVerifyRequest,
  GiftMessage,
  PasswordVerifyRequest,
  User,
} from "../types";

if (!process.env.BACKEND_URL) {
  throw new Error("Not found backend url");
}

class BackendService {
  private headers: Record<string, string> = {};
  private url: string = process.env.BACKEND_URL || "";
  private static instance: BackendService;

  constructor(headers: Record<string, string>) {
    this.headers = headers;
  }

  private setAccessToken(accessToken: string) {
    this.headers.Authorization = `${accessToken}`;
  }

  static getInstance(): BackendService {
    if (!BackendService.instance) {
      BackendService.instance = new BackendService({});
      const bearerToken = localStorage.getItem("bearerToken");
      if (bearerToken) {
        BackendService.instance.setAccessToken(bearerToken);
      }
    }
    return BackendService.instance;
  }

  private async get(path: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = `${this.url}${path}`;

        const res = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
        });
        const json: any = await res.json();
        resolve(json);
      } catch (e: any) {
        console.log(Error(e));
        reject(e);
      }
    });
  }

  private async post<T>(
    path: string,
    body: T,
    method: "POST" | "PUT" | "PATCH" = "POST"
  ) {
    try {
      const endpoint = `${this.url}${path}`;

      const response = await fetch(endpoint, {
        method,
        mode: "cors",
        credentials: "include",
        body: JSON.stringify(body),
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(json?.error);
      }

      return await response.json();
    } catch (e: any) {
      throw new Error(e);
    }
  }

  public async getAvailableGroups(): Promise<string[]> {
    return this.get("/groups")
      .then((res) => Promise.resolve(res.groups))
      .catch((e) => Promise.resolve([]));
  }

  public async getUserId(): Promise<number | null> {
    return new Promise((resolve) => {
      this.get("/userid")
        .then((res) => {
          resolve(res.user?.id || null);
        })
        .catch((e) => {
          resolve(null);
        });
    });
  }

  public async getUserData(userId: string): Promise<any> {
    return new Promise((resolve) => {
      this.get(`/userdata/${userId}`)
        .then((res) => {
          resolve(res.user);
        })
        .catch((e) => {
          resolve(null);
        });
    });
  }

  public async requestAuthenticationCode(data: CodeRequest): Promise<any> {
    this.post("/user/requestcode", data)
      .then((res) => Promise.resolve(res))
      .catch((e) => Promise.reject(e.error || e));
  }

  // groups/validateid

  public async validateIdByGroup(
    internalId: string,
    groupName: string,
    phone?: string
  ): Promise<boolean> {
    return this.post("/groups/validateid", { internalId, groupName, phone })
      .then((res: { result: boolean }) => {
        return res.result;
      })
      .catch((e) => {
        throw e.error || e;
      });
  }

  public async requestPassword(data: CodeRequest): Promise<any> {
    this.post("/user/requestpassword", data)
      .then((res) => Promise.resolve(res))
      .catch((e) => Promise.reject(e.error || e));
  }

  public async recoverPassword(data: CodeRequest): Promise<any> {
    this.post("/user/recoverpassword", data)
      .then((res) => Promise.resolve(res))
      .catch((e) => Promise.reject(e.error || e));
  }



  public async checkNewPassword(data: {
    password: string, internalId: string
  }): Promise<{id: number} | null> {
    return new Promise(async (resolve) => {
      this.post("/user/checktemppassword", data)
        .then((res: { id: number; success: boolean }) => {
          resolve({
            id: res.id
          });
        })
        .catch((e) => {
          resolve(null);
        });
    });
  }
  
  // "/user/checktemppassword"
  public async createUser(data: {
    password: string, internalId: string
  }): Promise<{id: number} | null> {
    return new Promise(async (resolve) => {
      this.post("/user/create", data)
        .then((res: { id: number; success: boolean }) => {
          resolve({
            id: res.id
          });
        })
        .catch((e) => {
          resolve(null);
        });
    });
  }


  public async validatePassword(
    data: PasswordVerifyRequest
  ): Promise<{ id: number; success: boolean } | null> {
    return new Promise(async (resolve) => {
      this.post("/user/authbypassword", data)
        .then((res: { id: number; success: boolean }) => {
          resolve({
            id: res.id,
            success: res.success,
          });
        })
        .catch((e) => {
          resolve(null);
        });
    });
  }

  public async validateAuthCode(
    data: CodeVerifyRequest
  ): Promise<{ id: number }> {
    return this.post("/user/auth", data);
  }

  public async updateUserProfile(userData: {
    name: string;
    surname: string;
    wish?: string;
  }): Promise<{ success: boolean }> {
    return this.post("/user/update", userData, "POST");
  }

  public async savePhoto(file: File) {
    return new Promise(async (resolve) => {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const endpoint = `${this.url}/user/loadphoto`;

        const response = await fetch(endpoint, {
          method: "POST",
          credentials: "include",
          body: formData,
          headers: new Headers({}),
        });

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || "Failed to upload photo");
        }

        const res = await response.json();
        resolve(res.success || false);
      } catch (e) {
        console.error(e);
        resolve(null);
      }
    });
  }

  public async deletePhoto() {
    return new Promise(async (resolve) => {
      await this.post("/user/deletephoto", {}, "POST")
        .then((res) => {
          resolve(res.success);
        })
        .catch((e) => {
          resolve(null);
        });
    });
  }

  // Gifts, roulette

  /* 
  apiApp.get("/gifts/usercount", userGiftCount);
  apiApp.get("/gifts/roulette", getUserGiftRecevier);
  apiApp.post("/gifts/make", makeGift);
  apiApp.post("/gifts/confirm", confirmGift);
  */


  /* 
    // Cards
  apiApp.post("/cards/make", createCardAction);
  apiApp.get("/cards/getnotifyinfo", getUserCards);
  apiApp.get("/cards/getreceviers", getSameGroupUserNames);
  apiApp.get("/cards/cardinfo", getCardInfo);
  apiApp.get("/cards/cardgiftinfo", getCardGiftInfo);
  */

  public async getUserGiftCount(): Promise<number> {
    return new Promise(async (resolve) => {
      await this.get("/gifts/usercount")
        .then((res) => {
          resolve(res.count);
        })
        .catch((e) => {
          resolve(3);
        });
    });
  }

  public async rotateRoulette(): Promise<{ id: number; data: User } | null> {
    return new Promise(async (resolve) => {
      await this.get("/gifts/roulette")
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          resolve(null);
        });
    });
  }

  public async makeGift(data: {to: number; message: string; deanon?: boolean, card?: CardData}): Promise<boolean> {
    return new Promise(async (resolve) => {
      await this.post("/gifts/make", {data}, "POST")
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          resolve(false);
        });
    });
  }

  public async confirmGift(data: {giftId: number, message: string}): Promise<boolean> {
    return new Promise(async (resolve) => {
      await this.post("/gifts/confirm", {data}, "POST")
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          resolve(false);
        });
    });
  }

  public async getCardNotifications() : Promise<CardNotifyInfo[]> {
    return new Promise(async (resolve) => {
      await this.get("/cards/getnotifyinfo")
        .then((res) => {
          resolve(res.cards);
        })
        .catch((e) => {
          resolve([]);
        });
    });
  }

  public async getCardFromNotifications() : Promise<CardNotifyInfo[]> {
    return new Promise(async (resolve) => {
      await this.get("/cards/getfromnotifyinfo")
        .then((res) => {
          resolve(res.cards);
        })
        .catch((e) => {
          resolve([]);
        });
    });
  }

  public async getCard(cardId: number): Promise<CardData | null> {
    return new Promise(async (resolve) => {
      await this.get(`/cards/cardinfo/${cardId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          resolve(null);
        });
    });
  }

  public async getCardByGift(giftId: number): Promise<CardData | null> {
    return new Promise(async (resolve) => {
      await this.get(`/cards/cardgiftinfo/${giftId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          resolve(null);
        });
    });
  }

  public async getGiftSender (giftId: number): Promise<User | null> {
    return new Promise(async (resolve) => {
      await this.get(`/gifts/giftsender/${giftId}`)
        .then((res) => {
          resolve(res.user);
        })
        .catch((e) => {
          resolve(null);
        });
    });
  }

  public async getGiftRecevier (giftId: number): Promise<User | null> {
    return new Promise(async (resolve) => {
      await this.get(`/gifts/giftrecevier/${giftId}`)
        .then((res) => {
          resolve(res.user);
        })
        .catch((e) => {
          resolve(null);
        });
    });
  }
}

export const staticUrl = `${process.env.STATIC_URL}/files/`;
export const backend = BackendService.getInstance();
