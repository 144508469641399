import React from "react";
import { SvgProps } from "../types";

export const GiftIcon = (props: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 48 43"
      className={props.classNames?.join(" ") || ""}
    >
      <path
        d="M20.6499 13.9631C20.4139 12.8593 20.1917 11.6583 20.761 10.6517C21.2955 9.70763 22.5034 9.1245 23.6835 9.18004C24.8637 9.23557 25.9744 9.89506 26.5367 10.8253C27.099 11.7555 27.1198 12.9079 26.6617 13.8798C24.7179 13.8242 22.7741 13.9006 20.6429 13.9631H20.6499Z"
        fill="#124659"
      />
      <path
        d="M39.1364 7.98583C39.1641 8.55508 39.0669 9.11738 38.7823 9.64497C38.1992 10.721 36.908 11.4082 35.6376 11.9358C32.7011 13.1576 29.4453 13.8449 26.134 13.7824C25.6828 13.7685 25.2315 13.7477 24.7873 13.713C24.6276 13.2756 24.4749 12.8383 24.1347 12.3246C25.058 8.83276 27.0018 5.53531 29.7647 2.79321C30.681 1.88381 31.7987 0.988295 33.2079 0.863339C34.5894 0.738383 35.9361 1.41176 36.79 2.32116C37.6438 3.23056 38.0881 4.34823 38.5047 5.44506C38.817 6.25728 39.1086 7.13197 39.1433 7.97195L39.1364 7.98583Z"
        fill="#124659"
      />
      <path
        d="M39.1363 7.98575C39.164 8.555 39.0669 9.1173 38.7822 9.64489C38.1991 10.7209 36.9079 11.4082 35.6375 11.9358C32.701 13.1575 29.4452 13.8448 26.1339 13.7823C27.3488 11.1444 29.8756 8.89515 33.0551 7.66642C34.0894 7.26378 35.214 6.95834 36.3595 6.99305C37.4077 7.02081 38.4768 7.35403 39.1363 7.98575Z"
        fill="#0D324A"
      />
      <path
        d="M22.2397 13.8241C22.2397 13.8241 22.2328 13.8518 22.2258 13.8657C22.1981 13.956 22.1703 14.0462 22.1356 14.1295C21.8857 14.1364 21.6427 14.1364 21.3928 14.1364C18.0259 14.1573 14.6521 13.7824 11.3963 13.0188C10.4314 12.7897 9.43866 12.5051 8.73057 11.9011C8.5362 11.7415 8.38347 11.554 8.24463 11.3596C7.67539 10.5405 7.57126 9.49225 7.65457 8.53425C7.78646 6.92371 8.32794 5.34093 9.23734 3.92476C9.77188 3.08478 10.4938 2.27257 11.5421 1.91853C13.1249 1.39093 14.902 2.09208 16.2696 2.95289C19.213 4.81334 21.3789 7.54155 22.3022 10.5474C22.6076 11.554 22.566 12.7064 22.2328 13.8241H22.2397Z"
        fill="#124659"
      />
      <path
        d="M22.2674 13.852C22.2674 13.852 22.2397 13.8659 22.2327 13.8659C21.9551 13.9631 21.6774 14.0603 21.3997 14.1366C18.0328 14.1575 14.659 13.7826 11.4032 13.019C10.4383 12.7899 9.44557 12.5053 8.73749 11.9013C8.54311 11.7416 8.39039 11.5542 8.25155 11.3598C8.23766 11.1516 8.26543 10.9503 8.34179 10.7559C8.56394 10.1797 9.18177 9.74929 9.8482 9.54797C10.5216 9.33971 11.2505 9.32582 11.9655 9.35359C15.9155 9.54102 19.7406 11.2071 22.2327 13.8173C22.2397 13.8312 22.2466 13.8381 22.2605 13.8451L22.2674 13.852Z"
        fill="#0D324A"
      />
      <path
        d="M25.3289 9.7146C26.0023 10.4296 26.4327 11.3182 26.5368 12.2484L25.3289 9.7146Z"
        fill="#0D324A"
      />
      <path
        d="M21.8857 9.72156C21.2817 10.4435 20.9971 11.5057 21.1498 12.4914L21.8857 9.72156Z"
        fill="#0D324A"
      />
      <path
        d="M44.6829 16.1425L44.4468 20.2382L43.1626 42.3763H4.80106L4.19016 33.789L3.87082 29.2073L3.44737 23.3552L3.00307 17.0935L2.93365 16.1425H44.6829Z"
        fill="#F87462"
      />
      <path
        d="M44.6827 16.1426L44.4467 20.2384C30.3961 21.1547 16.6856 22.4876 3.44722 23.3553L3.00293 17.0936L20.7397 16.1426H44.6827Z"
        fill="#DD534D"
      />
      <path
        d="M6.32106 31.4984C6.32106 32.7132 5.38389 33.7059 4.18986 33.7892L3.86359 29.2075C3.91912 29.2075 3.96771 29.2075 4.02325 29.2075C5.28669 29.2075 6.31411 30.2349 6.31411 31.5053L6.32106 31.4984Z"
        fill="#FFDAD1"
      />
      <path
        d="M25.6132 36.2468C25.6132 34.9764 26.6406 33.9559 27.904 33.9559C29.1675 33.9559 30.1949 34.9833 30.1949 36.2468C30.1949 37.5102 29.1675 38.5377 27.904 38.5377C26.6406 38.5377 25.6132 37.5102 25.6132 36.2468Z"
        fill="#FFDAD1"
      />
      <path
        d="M33.4156 37.885C33.4156 35.9829 34.9568 34.4418 36.8589 34.4418C38.761 34.4418 40.3021 35.9829 40.3021 37.885C40.3021 39.7871 38.761 41.3282 36.8589 41.3282C34.9568 41.3282 33.4156 39.7871 33.4156 37.885Z"
        fill="#FFDAD1"
      />
      <path
        d="M9.41058 36.2467C9.41058 33.935 11.2849 32.0607 13.5966 32.0607C15.9083 32.0607 17.7826 33.935 17.7826 36.2467C17.7826 38.5584 15.9083 40.4327 13.5966 40.4327C11.2849 40.4327 9.41058 38.5584 9.41058 36.2467Z"
        fill="#FFDAD1"
      />
      <path
        d="M29.4589 24.9729C29.4589 23.1263 30.9584 21.6338 32.798 21.6338C34.6377 21.6338 36.1371 23.1333 36.1371 24.9729C36.1371 26.8125 34.6377 28.312 32.798 28.312C30.9584 28.312 29.4589 26.8125 29.4589 24.9729Z"
        fill="#FFDAD1"
      />
      <path
        d="M40.7814 31.9912C42.0581 31.9912 43.0931 30.9562 43.0931 29.6795C43.0931 28.4028 42.0581 27.3678 40.7814 27.3678C39.5047 27.3678 38.4697 28.4028 38.4697 29.6795C38.4697 30.9562 39.5047 31.9912 40.7814 31.9912Z"
        fill="#FFDAD1"
      />
      <path
        d="M17.1159 24.2373C17.1159 25.9381 15.7414 27.3126 14.0406 27.3126C12.3398 27.3126 10.9653 25.9312 10.9653 24.2373C10.9653 23.7097 11.0972 23.2238 11.3332 22.7934C11.84 21.8284 12.8604 21.1689 14.0336 21.1689C15.0402 21.1689 15.9288 21.6479 16.4842 22.3977C16.8729 22.9114 17.102 23.5501 17.102 24.2443L17.1159 24.2373Z"
        fill="#FFDAD1"
      />
      <path
        d="M16.498 22.3907C14.7694 22.5226 13.0548 22.6615 11.3401 22.7864C11.8469 21.8215 12.8673 21.162 14.0405 21.162C15.0471 21.162 15.9357 21.641 16.4911 22.3907H16.498Z"
        fill="#E0ADA8"
      />
      <path
        d="M1.37164 20.148L0.0734863 12.3243H47.5498L46.5363 19.5857L1.37164 20.148Z"
        fill="#F87462"
      />
      <path
        d="M20.8788 12.0884L21.5522 42.536L26.3769 42.5291L26.9878 12.137L20.8788 12.0884Z"
        fill="#124659"
      />
    </svg>
  );
};
