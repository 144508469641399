import React from "react";
import { mainMenuTabs } from "../../content";
import { NavLink } from "react-router-dom";
import { Star4Icon } from "../../icons/star4";
import { BellIcon } from "../../icons/bell";
import { RoundArrowIcon } from "../../icons/roundArrow";

const MainMenu = (props: { opened?: boolean, onBellClick: () => void }) => {
  return (
    <div className={`mainMenu${props.opened ? " open" : ""}`}>
      <div className="logoSection mobile">
        <div className="inner">
          <div className="logo">
            <img src="/images/logo.svg" height="35" />
          </div>
        </div>
      </div>
      <nav className="inner flex menuInner">
        {mainMenuTabs.map((tab, index) => {
          return (
            <NavLink
              key={`mnav${index}`}
              to={tab.url}
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
              {tab.name}
            </NavLink>
          );
        })}
      </nav>
      <div className="menuImageBlock mobile">
        <div className="inner">
          <div className="flex"></div>
        </div>
        <div className="bottomBlock">
          <div className="inner flex bottomHint">
            <div className="openQRCode">
              <img src="/images/code.png" width="146" height="146" />
            </div>
            <div className="qrCodeHint">
                <h4 className="qrCodeHintText">
                   Скачай Новогодние стикеры для Телеграм
                </h4>
                <RoundArrowIcon width={113} height={46} />
            </div>
          </div>
          <div className="inner flex between bottomBlockInner">
            <a href="/profile" className="mobile">
              <div className="btn flex entryBtn">
                <Star4Icon
                  width={29}
                  height={29}
                  classNames={["starIcon1", "snowIcon"]}
                />
                <span>Кабинет Деда Мороза</span>
              </div>
            </a>
            <div className="notifyBtn mobile" onClick={props.onBellClick}>
              <BellIcon width={30} height={30} classNames={["svgBtn"]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainMenu;
