import React, { useState, useEffect } from "react";
import { CardImage } from "../../types";
import CardImageDeleter from "./deleter";

export const CardImageFC = (props: { image: CardImage; editable?: boolean }) => {
  const { image, editable } = props;
  const [toDelete, setToDelete] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest(".cardImgDeleter") && !target.closest("img")) {
      setToDelete(false);
    }
  };

  useEffect(() => {
    if (toDelete) {
      window.addEventListener("click", handleClickOutside);
    } else {
      window.removeEventListener("click", handleClickOutside);
    }

    // Очистка событий при размонтировании компонента
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [toDelete]);

  const toggleDelete = () => {
    if (editable) {
      setToDelete((prev) => !prev);
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        marginLeft: image.position.x,
        marginTop: image.position.y,
      }}
    >
      <img
        src={image.url}
        onClick={toggleDelete}
        style={{ cursor: editable ? "pointer" : "default" }}
      />
      {toDelete && <CardImageDeleter imageId={image.id} />}
    </div>
  );
};