import { SvgProps } from "../types";
import { Star4Icon } from "../icons/star4";
import React, { useEffect, useState } from "react";
import { endDate } from "../content/timer";

const iconParams: SvgProps = {
  width: 21,
  height: 21,
  classNames: ["starIcon2", "timerIcon"],
};

// export const endDate = new Date("2024-12-21");

const GlobalTimer = (props: { heading: string, hint: string }) => {
  const [values, updateValues] = useState({
    days: 20,
    hours: 14,
    minutes: 31,
    seconds: 28,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = endDate.getTime() - now.getTime();

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        updateValues({ days, hours, minutes, seconds });
      } else {
        // Таймер закончился
        updateValues({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    // Вызываем расчет сразу, чтобы не ждать первую секунду
    calculateTimeLeft();

    // Устанавливаем интервал для обновления каждую секунду
    const timer = setInterval(calculateTimeLeft, 1000);

    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="timer">
      <div className="inner columnCenter">
        <h3 className="timerHeading">{props.heading}</h3>
       { <div className="flex timerBody">
          <div className="timerItem">
            <div className="timerValue">{values.days < 10 ? `0${values.days}` : values.days}</div>
            <div className="timeMetric">дней</div>
          </div>
          <Star4Icon {...iconParams} />
          <div className="timerItem">
            <div className="timerValue">{values.hours < 10 ? `0${values.hours}` : values.hours}</div>
            <div className="timeMetric">часов</div>
          </div>
          <Star4Icon {...iconParams} />
          <div className="timerItem">
            <div className="timerValue">{values.minutes < 10 ? `0${values.minutes}` : values.minutes}</div>
            <div className="timeMetric">минут</div>
          </div>
          <Star4Icon {...iconParams} />
          <div className="timerItem">
            <div className="timerValue">{values.seconds < 10 ? `0${values.seconds}` : values.seconds}</div>
            <div className="timeMetric">секунд</div>
          </div>
        </div>}
        <div className="timerHint">
             {props.hint}
        </div>
      </div>
    </div>
  );
};

export default GlobalTimer;
