import React from "react";
import HomepageTimer from "../timer";

const EntrieScreenLand = () => {
  return (
    <div
      className="section screenSection"
      style={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <div className="inner flex columnCenter">
        <div style={{ height: 0 }} />
        <h1
          className="mainHeading"
        >
          Клуб анонимных Дедов Морозов
        </h1>
        <div className="columnCenter timer landingTimer" style={{
            maxHeight: 300
        }}>
          <HomepageTimer heading="До открытия клуба"  hint="Успей подарить с 18.12.2024 до 24.12.2024" />
          <div className="landingSubText"
            style={{
              marginTop: 0,
              marginBottom: 40,
            }}
          >
            <p>
              Клуб откроет свои двери <br /> <span 
              className="timerValue"> 11 декабря в 09:00
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntrieScreenLand;
