import { CardData, CardImageSample } from "../types";

export const cardImages: CardImageSample[] = [
    {
        id: 1,
        url: "/images/card/1.png"
    },
    {
        id: 2,
        url: "/images/card/2.png"
    },
    {
        id: 3,
        url: "/images/card/3.png"
    },
    {
        id: 4,
        url: "/images/card/4.png"
    },
    {
        id: 5,
        url: "/images/card/5.png"
    },
    {
        id: 6,
        url: "/images/card/6.png"
    },
    {
        id: 7,
        url: "/images/card/7.svg"
    },
    {
        id: 8,
        url: "/images/card/8.svg"
    }
]

export const defaultCardConfig: CardData = {
    independent: false,
    editable: true,
    title: "С Новым годом",
    images: [{
        id: 1,
        url: "/images/card/1.png",
        position: {
            x: 0,
            y: 10
        }
    }, {
        id: 2,
        url: "/images/card/2.png",
        position: {
            x: -300,
            y: 310
        }
    }, {
        id: 3,
        url: "/images/card/4.png",
        position: {
            x: 250,
            y: 330
        }
    }]
}

export const emptyCardConfig: CardData = {
    independent: true,
    editable: true,
    title: "",
    message: "",
    images: []
}

export const fileEvents = {
    png: "createpng",
    pdf: "createpdf"
}

export const cardThemes = {
    template1: "template1",
    template2: "template2"
}