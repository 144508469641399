import React from "react";
import GlobalTimer from "./timer";

const ProfileHeader = () => {

    return(
        <div className="profileHeader">
            <div className="flex between inner profileHeaderInner">
                <div className="profilePageTitle">
                    <h1>Личный кабинет Деда Мороза</h1>
                </div>
                <GlobalTimer heading="Подарки принимаем еще" hint="Успей вступить в Клуб Анонимных Дедов Морозов до 20 декабря" />
            </div>
        </div>
    )
}

export default ProfileHeader;