import { SvgProps } from "../types";
import { Star4Icon } from "../icons/star4";
import React, { useEffect, useState } from "react";
import { endDate } from "../content/timer";

const iconParams: SvgProps = {
  width: 21,
  height: 21,
  classNames: ["starIcon2", "timerIcon"],
};

// export const endDate = new Date("2024-12-21");

const GlobalBanner = () => {
  return (
    <div className="timer banner" style={{ backgroundImage: "url('/images/banner/bg_desktop.svg')"}}>
      <div className="inner columnCenter">
        <h5 className="timerHeading">
          Успей вступить в Клуб анонимных Дедов Морозов до 20 декабря!
        </h5>
        <div className="timerDescription">
        С 17 декабря начнется самое интересное — запусти навигатор Деда Мороза и узнай, кто станет твоим счастливчиком.
          <br />
          <br />
          Зарегистрируйся прямо сейчас 
и сделай этот Новый год особенным для себя и своих коллег!
        </div>
        <div className="flex" style={{
           width: "100%",
           justifyContent: "flex-start"
        }}>
            <img src="/images/banner/gifts.svg" />
        </div>
      </div>
    </div>
  );
};

export default GlobalBanner;
