import React from "react";
import { SvgProps } from "../types";

export const FreeArrowIcon = (props: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 9 11"
      className={props.classNames?.join(" ") || ""}
    >
      <path d="M1 6.51859L4.5 9.43526M4.5 9.43526V0.564735M4.5 9.43526L8 6.51859" stroke="white"/>
    </svg>
  );
};
