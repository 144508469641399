import React from "react";
import HeaderLand from "../conponents/headerLand";
import EntrieScreenLand from "../conponents/screens/entrieScreenLand";
import PreloaderScreen from "../conponents/screens/preloader";

const LoadingPage = () => {
  return (
    <div className="app mainPage columnCenter landing" style={{
      maxHeight: "100vh"
    }}>
      <PreloaderScreen />
    </div>
  );
};

export default LoadingPage;