import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../conponents/header";
import { Card } from "../../conponents/card";
import { actions, RootState } from "../../state/reducer";
import { emptyCardConfig } from "../../content";
import EditCardActionMenu from "../../conponents/card/saveMenu";

const CardEditPage = (props: {theme: string }) => {
  const [isWatch, setIsWatch] = useState(false)
  const State = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  
  useEffect(() => {
     dispatch(actions.editCard(emptyCardConfig))
  }, []);


  return (
    <div className="app profilePage columnCenter cardPage">
      <Header />
      <div style={{ height: 100 }} />
      <div className="section cardFreeEditor">
        <div className="roulette">
          <div className="inner">
            <div className="flex columnCenter rouletteHeading">
              <Card card={emptyCardConfig} editable={!isWatch} theme={props.theme} />
              <div className="btn mainSubmitBtn" onClick={() => setIsWatch(state => !state)}>
                  {isWatch ? "Вернуться к редактированию" : "Предпросмотр"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditCardActionMenu editable={!isWatch} onFileStart={() => setIsWatch(state => !state)} />
    </div>
  );
};

export default CardEditPage;
