import React from "react";
import { SvgProps } from "../types";

export const Star4Icon = (props: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      className={props.classNames?.join(" ") || ""}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M8.47167 6.5976L9.59371 0L10.7281 6.6059C10.8737 7.45369 11.545 8.11342 12.3952 8.24417L19 9.25992L12.3605 10.4512C11.5279 10.6006 10.8793 11.2577 10.7407 12.0922L9.59371 19L8.45922 12.1011C8.32126 11.2622 7.66802 10.6022 6.83057 10.4556L0 9.25992L6.79674 8.24014C7.65125 8.11193 8.3268 7.44945 8.47167 6.5976Z"
      />
    </svg>
  );
};
