import React from "react";
import { ArrowDownIcon } from "../../icons/arrowDown";
import { Star4Icon } from "../../icons/star4";


const EntrieScreen = () => {

    return(
        <div className="section screenSection">
            <div className="inner flex columnCenter">
                <h1 className="mainHeading">
                    Клуб анонимных Дедов Морозов
                </h1>
                <div className="flex mobile headingStars">
                    <Star4Icon width={31} height={31} classNames={["starIcon3", "snowIcon", "noCenter"]} />
                    <Star4Icon width={51} height={51} classNames={["starIcon3", "snowIcon", "centerIcon"]}  />
                    <Star4Icon width={31} height={31} classNames={["starIcon3", "snowIcon", "noCenter"]}  />
                </div>
                <a href="#signup">
                    <div className="btn mainLinkBtn">
                        Я с вами!
                    </div>
                </a>
                <div className="arrowCtnr">
                    <ArrowDownIcon width={81} height={44} />
                </div>
            </div>
        </div>
    )
}

export default EntrieScreen;