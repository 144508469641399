import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, RootState } from "../state/reducer";
import MainMenu from "./menu/mainMenu";
import { BellIcon } from "../icons/bell";
import { Star4Icon } from "../icons/star4";
import NotifyBlockDesktop from "./notify/desktop";
import { NotifyBlockMobile } from "./notify";

const Header = () => {
  const State = useSelector((state: RootState) => state);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notifyOpen, setNotifyOpen] = useState(false);

  const user = State.currentUser;
  const unreadCount = State.messages.filter((msg) => !msg.is_read).length;
  const notifyHidden = State.hideNotify;

  return (
    <div className="header">
      <div className="headerInner">
        <div className="logo">
          <a href="/">
            <img src="/images/logo.svg" height="35" />
          </a>
        </div>
        <div className="flex menuBlock">
          <div
            className={`mobile flex svgBtn mobileMenuBurger${
              mobileOpen ? " open" : ""
            }`}
            onClick={() => {
              setMobileOpen((state) => !state);
              setNotifyOpen(false);
            }}
          >
            <div className="burgerArrowBlock">
              <div className="line burgerLine1" />
              <div className="line burgerLine2" />
            </div>
            <div className="burgerText">{mobileOpen ? "Закрыть" : "Меню"}</div>
          </div>
          <MainMenu
            opened={mobileOpen}
            onBellClick={() => setNotifyOpen((state) => !state)}
          />
          <div style={{ position: "relative" }}>
            {!notifyHidden ? (
              <div
                className="notifyBtn pc"
                onClick={() => setNotifyOpen((state) => !state)}
              >
                <BellIcon width={22} height={22} classNames={["svgBtn"]} />
                {unreadCount > 0 ? (
                  <div
                    className="notifyAlert mpTop"
                    style={notifyOpen ? { display: "none" } : {}}
                  />
                ) : null}
              </div>
            ) : null}
            {notifyOpen && (
              <NotifyBlockDesktop
                onClose={() => setNotifyOpen((state) => !state)}
              />
            )}
          </div>
          <a href="/profile" className="pc">
            <div className="btn flex entryBtn">
              <Star4Icon
                width={19}
                height={19}
                classNames={["starIcon1", "snowIcon"]}
              />
              <span>
                {!user
                  ? "Кабинет Деда Мороза"
                  : `Дед Мороз ${user.last_name} ${user.first_name}`}
              </span>
            </div>
          </a>
        </div>
      </div>
      {notifyOpen && (
        <NotifyBlockMobile onClose={() => setNotifyOpen((state) => !state)} />
      )}
    </div>
  );
};

export default Header;
