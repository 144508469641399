import { actions } from "../../state/reducer";
import store from "../../state/store";
import { CardData, CardImage, Position } from "../../types";

export function getImageMaxId (data: CardData): number {
    return data.images.reduce((max, item) => {
        if (!item.id) return 0;
        return item.id > max ? item.id : max;
    }, 0);
}

export function getImageCoords(clickX: number, clickY: number): Position {
    let startY = 471;
    const startX = document.documentElement.clientWidth / 2;
    const cardOnScreen = document.getElementById("imageCard");
    if (cardOnScreen) {
        const rect = cardOnScreen.getBoundingClientRect();
        startY = rect.top + window.scrollY;
    }

    const correctedX = clickX - startX;
    const correctedY = clickY - startY;

    return { x: correctedX, y: correctedY };
}

export function addImageToCard (data: CardImage) {
    const state = store.getState();
    const edited = state.editCard;
    const newId = getImageMaxId(edited) + 1;
    const newImage = {...data, id: newId};
    const newImages = [...edited.images, newImage];
    const newEdited = {...edited, images: newImages};
    store.dispatch(actions.editCard(newEdited))
}

