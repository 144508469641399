import React from "react";
import HomepageTimer from "../timer";
import GlobalBanner from "../../conponents/banner";

const HomepageFormScreen = () => {

    return(
        <div className="section">
            <div className="columnCenter">
                <GlobalBanner />
                {/* <HomepageTimer heading="" /> */}
            </div>
        </div>
    )
}

export default HomepageFormScreen;