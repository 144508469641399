import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "../../icons/close";
import { actions, RootState } from "../../state/reducer";

const CardImageDeleter = (props: { imageId?: number }) => {
  const State = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const deleteSelf = () => {
    const card = State.editCard;
    const newImages = card.images.filter((item) => {
      return item.id !== props.imageId;
    });
    const newCard = { ...card, images: newImages };
    dispatch(actions.editCard(newCard));
  };


  return (
    <div className="cardImgDeleter" onClick={deleteSelf}>
      <CloseIcon width={8} height={8} />
      <span>удалить</span>
    </div>
  );
};

export default CardImageDeleter;
