import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, RootState } from "../../state/reducer";
import { HideIcon } from "../../icons/hide";
import { CloseIcon } from "../../icons/close";

export const NotifyBlockMobile = (props: { onClose: () => void }) => {
  const State = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const notifyList = State.messages;
  const notifyFromList = State.messagesFrom;

  const unreadCount = State.messages.filter((msg) => !msg.is_read).length;

  return (
    <div className="notifyBlock mobileBlock mobile">
      <div className="logoSection mobile">
        <div className="inner">
          <div className="logo">
            <img src="/images/logo.svg" height="35" />
          </div>
        </div>
      </div>
      <div className="flex between notifyPcHeading">
        <div className="nHeadingText">Уведомления</div>
        {unreadCount > 0 ? (
          <div className="flex notifyCounterBlock">
            <div className="notifyAlert" />
            <div className="notifyAlertCounter">{unreadCount}</div>
          </div>
        ) : null}
      </div>
      <div className="flex columnCenter">
        <div className="notifyMobileBlock">
          {notifyList.length === 0 ? (
            <div className="emptyList">Пока нет новых уведомлений</div>
          ) : (
            notifyList.map((item, index) => {
              const displayDate = item.date
                ? (() => {
                    const dt = new Date(item.date);
                    return isNaN(dt.getTime())
                      ? ""
                      : `${dt.getDate()}.${
                          dt.getMonth() + 1
                        }.${dt.getFullYear()}`;
                  })()
                : "";
              return (
                <div
                  key={`ntpc${index}`}
                  className={`flex between notifyMobileItem${
                    !item.is_read ? " unread" : ""
                  }`}
                >
                  <div className="messageText">
                    <a
                      href={
                        item.gift_id
                          ? `/gift/${item.gift_id}`
                          : `/card/${item.id}`
                      }
                    >
                      {item.gift_id
                        ? "Вам отправили подарок"
                        : "Вам отправили открытку"}
                    </a>
                  </div>
                  <div className="messageDate">{displayDate}</div>
                </div>
              );
            })
          )}
          {notifyFromList.map((item, index) => {
              const displayDate = item.date
                ? (() => {
                    const dt = new Date(item.date);
                    return isNaN(dt.getTime())
                      ? ""
                      : `${dt.getDate()}.${
                          dt.getMonth() + 1
                        }.${dt.getFullYear()}`;
                  })()
                : "";
              return (
                <div
                  key={`ntpc${index}`}
                  className="flex between notifyMobileItem"
                >
                  <div className="messageText">
                    <a
                      href={
                        item.gift_id
                          ? `/gift/${item.gift_id}`
                          : `/card/${item.id}`
                      }
                    >
                      {item.gift_id
                        ? "Вы отправили подарок"
                        : "Вы отправили открытку"}
                    </a>
                  </div>
                  <div className="messageDate">{displayDate}</div>
                </div>
              );
            })
          }
        </div>
        <div className="notifyBottomContainer">
          <div className="inner flex between">
            <div className="btn flex hideAll" style={{ visibility: "hidden" }}>
              <HideIcon width={16} height={16} />
              <span>Скрыть все</span>
            </div>
            <div className="btn flex close" onClick={props.onClose}>
              <CloseIcon width={16} height={16} />
              <span>Закрыть</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotifyBlockMobile;
