import React from "react";
import { SvgProps } from "../types";

export const HideIcon = (props: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 16 16"
      className={props.classNames?.join(" ") || ""}
    >
      <g clipPath="url(#clip0_1_1809)">
        <path
          d="M8.10815 2.93726C4.98315 2.93726 2.3144 4.88101 1.23315 7.62476C2.3144 10.3685 4.98315 12.3123 8.10815 12.3123C11.2332 12.3123 13.9019 10.3685 14.9832 7.62476C13.9019 4.88101 11.2332 2.93726 8.10815 2.93726ZM8.10815 10.7498C6.38315 10.7498 4.98315 9.34976 4.98315 7.62476C4.98315 5.89976 6.38315 4.49976 8.10815 4.49976C9.83315 4.49976 11.2332 5.89976 11.2332 7.62476C11.2332 9.34976 9.83315 10.7498 8.10815 10.7498ZM8.10815 5.74976C7.07065 5.74976 6.23315 6.58726 6.23315 7.62476C6.23315 8.66226 7.07065 9.49976 8.10815 9.49976C9.14565 9.49976 9.98315 8.66226 9.98315 7.62476C9.98315 6.58726 9.14565 5.74976 8.10815 5.74976Z"
          fill="#121540"
        />
        <path d="M2.47681 1.43848L14.429 13.3906" />
      </g>
      <defs>
        <clipPath id="clip0_1_1809">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(0.608154 0.124756)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
