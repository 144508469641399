import React from "react";
import { NavLink } from "react-router-dom";
import { footerMenuTabs } from "../../content";

const BottomMenu = () => {
  return (
    <div>
      <div className="flex bottomMenu">
      <div className="flex between bottomMenuInner">
        {footerMenuTabs.map((tab, index) => {
          return (
            <NavLink
              key={`mnav${index}`}
              to={tab.url}
              className={({ isActive }) => (isActive ? "active-link" : "link")}
            >
              {tab.name}
            </NavLink>
          );
        })}
      </div>
      </div>
      <div className="orgText">
         <p>ООО «Хохланд Руссланд», ОГРН 1025005117631, 140126, Московская область, г.о. Раменский, п. Раменской
            агрохимстанции (РАОС), д. 16</p>
         <p>ООО «Промогрупп»,
            ОГРН 1137746927670, 107023, Москва, Семёновский
            переулок, 19</p>
      </div>
    </div>
  );
};

export default BottomMenu;
