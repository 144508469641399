import React, { useEffect, useState } from "react";

const TextPageScreen = ({children}) => {
  return (
    <div className="section">
      <div className="columnCenter">
        <div className="profileContent">
          <div className="inner">
            <div className="profileText">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextPageScreen;
