import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardData, CardImage, Position } from "../../types";
import { actions, RootState } from "../../state/reducer";
import { cardImages } from "../../content";
import { addImageToCard, getImageCoords } from "./functions";

let imageHandler: (event: MouseEvent) => void;

export const CardImageEditor = (props: {dragStartHandler: (e: React.DragEvent<HTMLImageElement>,
    id: string) => void}) => {
  const { dragStartHandler } = props;
  const [imageAdded, setImageAdded] = useState(false);
  const [coordinates, setCoordinates] = useState<Position[]>([]);

  const handleDragStart = (e: React.DragEvent<HTMLImageElement>, id: string) => {
    e.dataTransfer.setData('imageId', id);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const imageId = e.dataTransfer.getData('imageId');
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Запоминаем координаты
    setCoordinates((prevCoordinates) => [
      ...prevCoordinates,
      { x, y, imageId },
    ]);
  };

  const addImage = (url: string) => (event: MouseEvent) => {
    // Получаем координаты клика
    if (imageAdded) return;
    setImageAdded(true)
    const { clientX, clientY } = event;
    const position = getImageCoords(clientX, clientY);
    const image: CardImage = {
      url,
      position,
    };
    addImageToCard(image);
    document.body.style.cursor = "default";
    setTimeout(() => {
        window.removeEventListener("mousedown", imageHandler);
    }, 900)
  };

  const selectItem = (url: string) => {
    return () => {
      imageHandler = addImage(url);
      document.body.style.cursor = `url(${url}), auto`;
      setTimeout(() => {
        setImageAdded(false);
        window.addEventListener("mousedown", imageHandler);
      }, 10)
    };
  };

  return (
    <div className="flex cardEditorContainer pc">
      <div className="inner columnCenter">
        {cardImages.map((item, index) => {
          return (
            <div
              key={`editchooseimg${index}`}
              className="cardEditorImage"
            >
              <img src={item.url} onDragStart={(e) => dragStartHandler(e, item.url)} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
