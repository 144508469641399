import React from "react";

const HeaderLand = () => {

  return (
    <div className="header">
      <div className="headerInner" style={{
        justifyContent: "center"
      }}>
        <div className="logo">
          <a href="/">
            <img src="/images/logo.svg" height="35" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeaderLand;
