import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileHeader from "../../conponents/profileHeader";
import Header from "../../conponents/header";
import TextPageScreen from "../../conponents/screens/textScreen";

const PrivacyPolicyPage = () => {
  return (
    <div className="app profilePage columnCenter">
      <Header />
      <div className="section screenSection">
        <ProfileHeader />
        <TextPageScreen>
          <h5>Согласие на обработку персональных данных</h5>
          <p>
            Настоящим я выражаю свое информированное согласие (далее – Согласие)
            на обработку ООО «Хохланд Руссланд», ОГРН 1025005117631, место
            нахождения 140126, Московская область, г.о. Раменский, п. Раменской
            агрохимстанции (РАОС), д. 16 (далее – Компания), ООО «Промогрупп»,
            ОГРН 1137746927670, место нахождения 107023, Москва, Семёновский
            переулок, 19 в качестве оператора (далее по тексту - Оператор) моих
            персональных данных и подтверждаю, что, предоставляя такое согласие,
            я действую своей воле и в своих интересах.
          </p>
          <p>
            Согласие дается мной в целях участия в новогодних активностях «Клуб
            анонимных дедов Морозов»: регистрации на сайте; выбора одаряемого
            коллеги при помощи колеса фортуны; создания он-лайн открытки к
            Новому году.
          </p>
          <p>
            Настоящее согласие дается мной на обработку следующих персональных
            данных:{" "}
          </p>
          <p>• фамилия, имя, отчество (при наличии);</p>
          <p>• табельный номер;</p>
          <p>• геопозиция IP адреса;</p>
          <p>• номер телефона для получения кода регистрации;</p>
          <p>
            • тип устройства, с которого осуществляется доступ к сайтам или
            мобильным приложениям, администрируемым/используемым Оператором.
          </p>
          <p>
            Персональные данные Пользователя хранятся исключительно на
            электронных носителях и обрабатываются с использованием
            автоматизированных систем, за исключением случаев, когда
            неавтоматизированная обработка персональных данных необходима в
            связи с исполнением требований законодательства.
          </p>
          <p>
            Персональные данные обрабатываются до получения письменного
            уведомления об их отзыве или до достижения цели обработки
            персональных данных, в зависимости от того, что произошло раньше.
            Согласие может быть отозвано в любое время на основании моего
            письменного заявления, составленного в соответствии с требованиями
            действующего законодательства Российской Федерации и направленного
            по адресу электронной почты Компании rus.raos@hochland.ru.
          </p>
          <p>
            В случае, если персональные данные будут обрабатываться до получения
            письменного уведомления об их отзыве я согласен с тем, что
            предоставленные мной персональные данные будут удалены по моему
            письменному требованию в течение 10 (Десяти) рабочих дней с даты
            получения требования об их уничтожении.
          </p>
          <p>
            Оператор обязуется не передавать полученные персональные данные
            третьим лицам, за исключением следующих случаев:{" "}
          </p>
          <p>
            • По запросам уполномоченных органов государственной власти РФ
            только по основаниям и в порядке, установленным законодательством
            РФ.
          </p>
        </TextPageScreen>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
