import React from "react";
import { SvgProps } from "../types";

export const BellIcon = (props: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      className={props.classNames?.join(" ") || ""}
    >
      <path
        d="M5.19531 17.5C4.69531 17.5 4.29427 17.3594 3.99219 17.0781C3.6901 16.7917 3.53906 16.4193 3.53906 15.9609C3.53906 15.6432 3.60417 15.349 3.73438 15.0781C3.86458 14.8073 4.03646 14.5573 4.25 14.3281C4.46354 14.0938 4.70052 13.8724 4.96094 13.6641C5.17448 13.4922 5.33594 13.2552 5.44531 12.9531C5.5599 12.651 5.64844 12.2995 5.71094 11.8984C5.77865 11.4974 5.84896 11.0599 5.92188 10.5859C6.01562 9.76302 6.1901 9.01302 6.44531 8.33594C6.70052 7.65365 7.04167 7.07292 7.46875 6.59375C7.90104 6.10938 8.42708 5.75781 9.04688 5.53906C9.24479 5.00781 9.57292 4.57552 10.0312 4.24219C10.4896 3.90885 11.0052 3.74219 11.5781 3.74219C12.1615 3.74219 12.6797 3.90885 13.1328 4.24219C13.5911 4.57552 13.9193 5.00781 14.1172 5.53906C14.7422 5.75781 15.2682 6.10938 15.6953 6.59375C16.1276 7.07292 16.4688 7.65365 16.7188 8.33594C16.974 9.01302 17.151 9.76302 17.25 10.5859C17.3229 11.0599 17.3906 11.4974 17.4531 11.8984C17.5156 12.2995 17.6042 12.651 17.7188 12.9531C17.8333 13.2552 17.9974 13.4922 18.2109 13.6641C18.4661 13.8724 18.7005 14.0938 18.9141 14.3281C19.1328 14.5573 19.3047 14.8073 19.4297 15.0781C19.5599 15.349 19.625 15.6432 19.625 15.9609C19.625 16.4193 19.474 16.7917 19.1719 17.0781C18.8698 17.3594 18.4688 17.5 17.9688 17.5H5.19531ZM11.5781 20.9922C11.0625 20.9922 10.6016 20.8854 10.1953 20.6719C9.78906 20.4635 9.46094 20.1823 9.21094 19.8281C8.96615 19.4792 8.82812 19.0911 8.79688 18.6641H14.3672C14.3359 19.0911 14.1979 19.4792 13.9531 19.8281C13.7083 20.1823 13.3802 20.4635 12.9688 20.6719C12.5625 20.8854 12.099 20.9922 11.5781 20.9922Z"
        fill="#FFF"
      />
    </svg>
  );
};
