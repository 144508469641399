import { GiftIcon } from "../../icons/gift";
import React, { useEffect, useState } from "react";


const PreloaderScreen = () => {

    const [loadingText, setLoadingText] = useState("Загрузка.");

    useEffect(() => {
        const timer = setInterval(() => {
            setLoadingText(prevText => {
                // Keep adding dots until there are 3, then reset back to 1
                if (prevText.length < 12) {
                    return prevText + '.';
                } else {
                    return "Загрузка.";
                }
            });
        }, 500); // Update every 500ms (0.5 seconds)

        // Cleanup timer when the component unmounts
        return () => clearInterval(timer);
    }, []);

    return(
        <div className="section loadingSection">
            <div className="preloader columnCenter">
                <div className="columnCenter">
                    <img src="/images/preloader.svg" />
                    <h1>{loadingText}</h1>
                </div>
            </div>
        </div>
    )
}

export default PreloaderScreen;